<template lang="pug">
.page
  el-card(header="门店管理")
    el-form
      el-form-item(label="区域")
        el-radio-group(v-model="filter.region")
          el-radio-button(label="") 全部
          el-radio-button(v-for="i in regionList" :label="i.id") {{i.regionTitle}}
      el-form-item(label="楼层")
        el-radio-group(v-model="filter.floor" @change="getList")
          el-radio-button(label="") 全部
          el-radio-button(v-for="i in floorList" :label="i.id") {{i.floorTitle}}
  .storeList
    .storeItem(v-for="i in storeList" :class='{isAvtive:pickedList.includes(i.id),isHide:i.hideStatus==1,isPC:!isMobile}')
      img.logo(:src="i.storeLogo")
      .info
        .title {{i.storeTitle}}
        .desc 楼层： {{i.commercialPlazaRegionTitle}}
        .status
          el-switch(active-text="显示" inactive-text="隐藏" :active-value="0" :inactive-value="1" v-model="i.hideStatus" @change="onStoreHideStatusChange($event,i)")
      .checkbox(@click="handlePick(i)")
        el-icon
          Check
  .pagination
    el-pagination(layout="total, prev, pager, next" background v-model:page-size="pagination.size" :pager-count="isMobile?3:5" :page-sizes="[10, 20, 50, 100]" :total="pagination.total" v-model:current-page="pagination.page" @current-change="getList")
  .footer(v-if="pickedList.length")
    .pickStatus 已选 {{pickedList.length}} 个门店
    .actionList
      el-button(size="small" type="danger" @click="deleteBatch") 删除
      el-button(size="small" type="success" @click="handleBatchHide") 切换显示隐藏
      el-button(size="small" type="primary" @click="isShowBatchUpdateFloorDialog=true") 更改楼层
  .jumpAdd(@click="router.push('/store/add')" v-if="isShowAdd")
    el-icon
      Plus
.cover(v-loading="true" element-loading-text="审核中..." v-if="isAudit")
el-dialog(v-model="isShowBatchUpdateFloorDialog" title="批量更改楼层")
  el-form
    el-form-item(label="区域")
      el-radio-group(v-model="batchUpdateFloorDialogData.region")
        el-radio-button(v-for="i in regionList" :label="i.id") {{i.regionTitle}}
    el-form-item(label="楼层")
      el-radio-group(v-model="batchUpdateFloorDialogData.floor" @change="getList")
        el-radio-button(v-for="i in batchFloorList" :label="i.id") {{i.floorTitle}}
  template(#footer)
    el-button(@click="isShowBatchUpdateFloorDialog=false") 取消
    el-button(type="primary" :disabled="!!errorMessageForBatchUpdateFloor" @click="handleSubmitBatchUpdateFloor") {{errorMessageForBatchUpdateFloor||'确定'}}
</template>

<script setup>
import { inject, onMounted, computed, ref, watch } from 'vue'
import { Check, Plus, Loading } from '@element-plus/icons-vue'
import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'

const { fetch, router, message, ElMessageBox } = inject('global')

const filter = ref({
  region: '',
  floor: ''
})

watch(
  () => filter.value.region,
  () => {
    filter.value.floor = ''
    getList()
  }
)

const isShowAdd = ref(false)
const regionList = ref([])
const isAudit = ref(false)
const floorList = computed(() => {
  return regionList.value?.find(i => i.id === filter.value.region)?.floors || []
})
// const categoryList = ref([
//   { id: '1', name: '111' },
//   { id: '2', name: '222' },
//   { id: '3', name: '333' }
// ])
const storeList = ref([])
const pickedList = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 10
})

function getConfig() {
  return fetch
    .get(`/boom-center-product-service/sysAdmin/commercialPlaza/facilities/${currentMarketId.value}`)
    .then(res => {
      regionList.value = res.regionList
      filter.value.region = regionList.value?.[0]?.id || ''
      if (res.regionList.length != 0) {
        isShowAdd.value = true
      }
      return
    })
}
function getList() {
  let params = {
    ...pagination.value
  }
  if (filter.value.region) {
    params.commercialPlazaRegionId = filter.value.floor || filter.value.region
  }
  fetch
    .get(
      `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/pageRegionStoreRelation/${currentMarketId.value}`,
      { params }
    )
    .then(res => {
      storeList.value = res.records
      pagination.value.total = Number(res.total)
    })
}

function onStoreHideStatusChange(hideStatus, item) {
  return fetch
    .post(`/boom-center-product-service/sysAdmin/commercialPlazaRegion/relation/${currentMarketId.value}`, {
      commercialPlazaId: currentMarketId.value,
      commercialPlazaRegionId: item.commercialPlazaRegionId,
      stores: [{ businessId: item.businessId, storeId: item.storeId, hideStatus: hideStatus }]
    })
    .then(res => {
      message.success('更新状态成功')
      return
    })
}
function handlePick(item) {
  const n = pickedList.value.findIndex(i => i === item.id)
  if (n >= 0) {
    pickedList.value.splice(n, 1)
  } else {
    pickedList.value.push(item.id)
  }
}

const allPickedItem = computed(() => {
  return storeList.value.filter(i => pickedList.value?.includes(i.id)) || []
})

function deleteBatch() {
  isAudit.value = true
  fetch
    .post(
      `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/deleteRegionRelation/${currentMarketId.value}`,
      {
        commercialPlazaId: currentMarketId.value,
        ids: allPickedItem.value.map(v => v.id)
      }
    )
    .then(res => {
      isAudit.value = false
      message.success('删除成功')
      initData()
      isShowBatchUpdateFloorDialog.value = false
    })
    .catch(err => {
      isAudit.value = false
      message.error(err?.msg || err)
    })
}

function handleBatchHide() {
  const isAllHide = allPickedItem.value.every(i => i.hideStatus == 1)
  if (isAllHide) {
    Promise.all(allPickedItem.value.map(i => onStoreHideStatusChange(0, i))).then(() => {
      initData()
    })
  } else {
    Promise.all(allPickedItem.value.map(i => onStoreHideStatusChange(1, i))).then(() => {
      initData()
    })
  }
}
const isShowBatchUpdateFloorDialog = ref(false)
const batchUpdateFloorDialogData = ref({
  region: '',
  floor: ''
})
const batchFloorList = computed(() => {
  return regionList.value?.find(i => i.id === batchUpdateFloorDialogData.value.region)?.floors || []
})

watch(
  () => batchUpdateFloorDialogData.value.region,
  () => {
    batchUpdateFloorDialogData.value.floor = ''
  }
)
const errorMessageForBatchUpdateFloor = computed(() => {
  if (!batchUpdateFloorDialogData.value.region) return '请选择区域'
  if (!batchUpdateFloorDialogData.value.floor) return '请选择楼层'
  return ''
})
function handleSubmitBatchUpdateFloor() {
  if (errorMessageForBatchUpdateFloor.value) {
    message.warning(errorMessageForBatchUpdateFloor.value)
  }
  fetch
    .post(`boom-center-product-service/sysAdmin/commercialPlazaRegion/relation/${currentMarketId.value}`, {
      commercialPlazaId: currentMarketId.value,
      commercialPlazaRegionId: batchUpdateFloorDialogData.value.floor,
      stores: allPickedItem.value
    })
    .then(res => {
      message.success('更新状态成功')
      initData()
      isShowBatchUpdateFloorDialog.value = false
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}

async function initData() {
  pickedList.value = []
  pagination.value.page = 1
  await getConfig()
  getList()
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.cover {
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.storeList {
  --gutter: 10px;
  --logoSize: 80px;
  --checkboxSize: 50px;
  --size-text-title: 18px;
  --size-text-desc: 14px;
  display: flex;
  flex-wrap: wrap;
  .storeItem {
    .trans();
    margin: var(--gutter);
    padding: var(--gutter);
    background: white;
    border-radius: var(--gutter);
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 300px;
    border: 1px solid;
    border-color: var(--color-border);
    &.isPC {
      width: calc(33% - 20px);
      flex-basis: auto;
      flex-grow: unset;
    }
    &:hover {
      box-shadow: 0 0 12px var(--color-border);
    }
    .logo {
      width: var(--logoSize);
      height: var(--logoSize);
      margin-right: var(--gutter);
      flex-shrink: 0;
    }
    .info {
      flex-grow: 1;
      .title {
        font-size: var(--size-text-title);
        color: var(--color-text-title);
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .desc {
        font-size: var(--size-text-desc);
        color: var(--color-text-desc);
      }
    }
    .checkbox {
      .flexCenter();
      .rounded();
      flex-shrink: 0;
      cursor: pointer;
      width: var(--checkboxSize);
      height: var(--checkboxSize);
      font-size: calc(var(--checkboxSize) / 2);
      color: #999;
      &:hover {
        background: var(--color-pink);
        color: white;
      }
    }
    &.isAvtive {
      border-color: var(--color-primary);
      .checkbox {
        background: var(--color-primary);
        color: white;
      }
    }
    &.isHide {
      filter: grayscale(80%);
      filter: gray;
      opacity: 0.7;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
}
.footer {
  text-align: center;
  margin: 20px 0;
  .actionList {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 10px;
  }
}

.jumpAdd {
  position: fixed;
  bottom: 100px;
  right: 30px;
  font-size: 30px;
  text-align: center;
  line-height: 57px;
  background-color: orange;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #fff;
}

.isMobile {
  .storeList {
    --gutter: 10px;
    --logoSize: 40px;
    --checkboxSize: 30px;
    --size-text-title: 14px;
    --size-text-desc: 12px;
  }
}
</style>
